<template>
    <div class="pingjiaindex">
        <p class="link" @click="tourl">返回订单列表</p>

        <div class="mainbox">
            <p class="tips"> 您的评价是律师提高服务的动力与方向，对律师至关重要 ，我们期待您的真诚鼓励与善意批评。 </p>
            <div class="tuimg flexbox">
                <p @click.stop="changeping(1)">
                    <img src="@/assets/img/p1.png" alt="" v-if="p1 == false" >
                    <img src="@/assets/img/p11.png" alt="" v-if="p1">
                    <span>很满意</span>
                </p>
              
                <p @click.stop="changeping(2)">
                    <img src="@/assets/img/p2.png" alt="" v-if="p2 == false" >
                    <img src="@/assets/img/p22.png" alt="" v-if="p2">
                    <span>满意</span>
                </p>

                <p @click.stop="changeping(3)">
                    <img src="@/assets/img/p3.png" alt="" v-if="p3 == false" >
                    <img src="@/assets/img/p33.png" alt="" v-if="p3">
                    <span>一般</span>
                </p>

                <p @click.stop="changeping(4)">
                    <img src="@/assets/img/p4.png" alt="" v-if="p4 == false" >
                    <img src="@/assets/img/p44.png" alt="" v-if="p4">
                    <span>不满意</span>
                </p>

                <p @click.stop="changeping(5)">
                    <img src="@/assets/img/p5.png" alt="" v-if="p5 == false" >
                    <img src="@/assets/img/p55.png" alt="" v-if="p5">
                    <span>很不满意</span>
                </p>

            </div>

            <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 100}"
                placeholder="请输入您的评价内容，希望您能客观表达意见"
                v-model="textarea">
            </el-input>

            <div class="btnbox" @click="submit">立即评价</div>
        </div>
    </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
    components: {},
    data() {
        return {
            pingItem:'', // 1很满意 2 满意 3 一般 4 不满意 5 很不满意
            p1:false,
            p2:false,
            p3:false,
            p4:false,
            p5:false,
            textarea:''
        };
    },
    computed: {},
    watch: {},
    methods: {
        tourl() {
            this.$router.go(-1);
        },
        changeping(num){
            for (let i = 1; i< 6; i++) {
               if(i == num){
                this[`p${num}`] = !this[`p${num}`];
                this.pingItem = num;
               }else {
                this[`p${i}`] = false
               }
            }
        },
        // 评价
        submit(){
            var star = 5;
            if(this.pingItem == 1){
                star = 5
            } else if(this.pingItem == 2){
                star = 4
            } else if(this.pingItem == 3){
                star = 3
            } else if(this.pingItem == 4){
                star = 2
            }else if(this.pingItem == 5){
                star = 1
            }

            let data = {
                id: this.$route.query.id,
                pingjiaStar: star, // 星级
                pingjiaContent:this.textarea, // 内容
            }
            apiUrl.pinglunorder(data).then((res) => {
                if(res.code == 0){
                    this.$message.success('操作成功');
                    this.$router.go(-1);
                }
            });
        }
    },
    created() {},
    mounted() {},
    }
</script>
<style lang='scss'>
.pingjiaindex{
    .link{
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #305BFE;
        line-height: 18px;
        margin-bottom: 10px;
        cursor: pointer;
    }
    .mainbox{
        width: 1216px;
        background: #FFFFFF;
        border-radius: 13px;
        padding: 33px 40px 60px 40px;
        .tips{
            font-size: 13px;
            color: #777777;
            line-height: 18px;
        }
        .tuimg{
            margin-top: 27px;
            margin-bottom: 27px;
            p{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: 40px;
                cursor: pointer;
                img{
                    width: 33px;
                    height: 33px;
                }
                span{
                    margin-top: 7px;
                }
            }
           
        }
     
        .el-textarea__inner{
            width: 570px;
            background-color: #f8f8f8;
            border: 1px solid #f8f8f8;
        }
        
        .btnbox{
            width: 187px;
            height: 43px;
            line-height: 43px;
            border-radius: 30px;
            margin-top: 67px;
        }
    }
}

</style>